<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="entsale">
        <div class="con">
            <!-- <p class="con_tit">设置权限</p> -->
            <ul class="con_tit">
                <li v-for="(i,index) in chakan_list" :key="index" :class="chakan_num==index?'zise_num':''" @click="xuanze_zhanshi(index)">{{i}}</li>
            </ul>
            <ul class="con_tit_xuan">
                <li v-for="(i,index) in biaoti_list" :class="an_shenme==index?'zise':''" @click='an_shenme=index'  :key="index">{{i}}</li>
            </ul>
            <div class="con_box">
                <div class="con_box_left" v-if="an_shenme==0">
                    <!-- 
                        check-strictly -->
                    <el-tree
                        :data="faqi_ren_list"
                        class="tree_bumen"
                        show-checkbox
                        default-expand-all
                        node-key="id"
                        ref="all_staff_tree"
                        highlight-current
                        :default-checked-keys='gouxuan_key'
                        @check-change="xuanze_renyuan"
                        :props="defaultProps">
                    </el-tree>
                </div>
                <div class="con_box_left" v-if="an_shenme==1">
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" @keyup="keyu_jue" v-model="juese_sousuo">
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(i,index) in mianbao_list_jue" :key='index'  @click="mianbao_dian_jue(i,index)">
                            <p :class="mianbao_list_jue.length-1==index?'p1':'p2'">{{i.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <!-- <div class="quanxuan" v-if="qiye_juesery_list.length!=0">
                        <img class="img2" @click="dianji_quanxuan_jue" :src="quanxuan_jue?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div> -->
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_juese_list" :key="index" @click="dianji_bumen_jue(i)">
                                    <p style="margin-left:0.16rem;">{{i.role_group_name}}</p>
                                    <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(i,index) in qiye_juesery_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                    <!-- <input ref="have_jue" type="checkbox" @click="dianji_xuanren_jue(i,$event,index)"> -->
                                    <img class="img2" @click="dianji_xuanren_jue(i,index)" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    <p>{{i.role_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="con_box_right">
                    <ul>
                         <!-- @click="dianji_danxuan(i)" -->
                        <li v-for="(i,index) in renyuan_list" :class="Number(index)%2==0?'huise':''" :key="index">
                            <!-- <img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""> -->
                            <p class="touxiang">{{(i.role_name? i.role_name : i.label).split('')[0]}}</p>
                            <div>
                                <p>{{i.role_name? i.role_name : i.label}}</p>
                                <!-- <p>{{i.dept_name}}</p> -->
                            </div>
                            <!-- <i class="el-icon-error" @click="shanchu_ren(i)"></i> -->
                        </li>
                    </ul>
                </div>
            </div>
            <p class="con_foot">
                <span @click="dianji_baocun">保存</span>
            </p>
        </div>
    </div>
</template>

<script>
import { set_all_module_sale, query_all_module_sale_userid, query_dept_staff_tree_aim_not, query_ent_dept_list_aim_not, query_role_info_list, query_module_power_detail, set_module_power, query_role_group_info_list, query_dept_staff_tree_aim } from '../../api/api.js'
export default {
  name: 'entsale',
  data () {
    return {
      faqi_ren_list: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      renyuan_list: [],
      gouxuan_key: [], // 默认勾选的节点的 key 的数组
      biaoti_list: ['按组织架构', '按角色'],
      an_shenme: '0',
      ids: [],
      type: '0',
      mianbao_list_jue: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_juese_list: [],
      qiye_juesery_list: [],
      qiye_juesery_list_xuan: [],
      quanxuan_jue: false,
      juese_sousuo: '',

      chakan_list: ['设置组织架构权限', '设置销售模块查看权限'],
      chakan_num: '0'
    }
  },
  created () {
    this.chakan()
    this.mianbao_list_jue[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  watch: {
    an_shenme () {
      this.qiehuan_xuanze()
      this.renyuan_list = []
    }
  },
  mounted () {
      
  },
  methods: {
    jichu () {
      query_dept_staff_tree_aim_not({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.faqi_ren_list = date
          // if(this.ids.length!=0){
          this.$refs.all_staff_tree.setCheckedKeys(this.ids)
          this.renyuan_list = this.getSimpleCheckedNodes(this.$refs.all_staff_tree.store)
          // }
        } else if (res.data.code == 10169) {}
      })
    },
    chakan () {
      if (this.chakan_num == 1) {
        query_all_module_sale_userid({
          data: {
            ent_id: this.$ent_id(),
            read_flag: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            if (date != null) {
              console.log(date)
              let ids = []
              if (date.staff_ids != undefined) {
                ids.push(date.staff_ids)
              }
              if (date.dept_ids != undefined) {
                ids.push(date.dept_ids)
              }
              if (date.role_ids != undefined) {
                ids.push(date.role_ids)
              }
              ids = ids.join(',')
              ids = ids.split(',')
              this.an_shenme = Number(date.type) - 1
              this.type = Number(date.type) - 1
              console.log(ids)
              this.ids = ids
              // this.$refs.all_staff_tree.setCheckedKeys(ids);
              setTimeout(() => {
                this.qiehuan_xuanze()
              }, 0)
            } else {
              this.ids = []
              // this.$refs.all_staff_tree.setCheckedKeys(ids);
              setTimeout(() => {
                this.qiehuan_xuanze()
              }, 0)
            }
          } else if (res.data.code == 500) {}
        })
      } else {
        query_module_power_detail({
          data: {
            ent_id: this.$ent_id(),
            module_id: '2'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            if (date != null) {
              console.log(date)
              let ids = []
              if (date.staff_ids != undefined) {
                ids.push(date.staff_ids)
              }
              if (date.dept_ids != undefined) {
                ids.push(date.dept_ids)
              }
              if (date.role_ids != undefined) {
                ids.push(date.role_ids)
              }
              ids = ids.join(',')
              ids = ids.split(',')
              this.an_shenme = Number(date.type) - 1
              this.type = Number(date.type) - 1
              console.log(ids)
              this.ids = ids
              // this.$refs.all_staff_tree.setCheckedKeys(ids);
              setTimeout(() => {
                this.qiehuan_xuanze()
              }, 0)
            } else {
              this.ids = []
              // this.$refs.all_staff_tree.setCheckedKeys(ids);
              setTimeout(() => {
                this.qiehuan_xuanze()
              }, 0)
            }
          } else if (res.data.code == 500) {}
        })
      }
    },
    qiehuan_xuanze () {
      if (this.an_shenme == 0) {
        console.log(this.an_shenme)
        this.jichu()
      } else {
        query_role_group_info_list({
          data: {
            ent_id: this.$ent_id(),
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            for (let i = 0; i < date.length; i++) {
              query_role_info_list({
                data: {
                  ent_id: this.$ent_id(),
                  role_group_id: i.dept_id
                }
              }).then(ras => {
                if (ras.data.code == 200) {
                  const listdata = JSON.parse(ras.data.body.data)
                  console.log(99999, listdata)
                  listdata.map(item => {
                    this.$set(item, 'zhi', false)
                    this.$set(item, 'label', item.role_name)
                    this.$set(item, 'type', '3')
                  })
                  console.log(this.ids)
                  this.ids.map(item => {
                    listdata.map(itm => {
                      if (item == itm.role_id) {
                        this.renyuan_list.push(itm)
                      }
                    })
                  })
                  this.renyuan_list = this.$func.Es5duplicate(this.renyuan_list, 'role_id')
                } else if (ras.data.code == 500) {
                }
              })
            }
          } else if (res.data.code == 500) {
          }
        })
        this.dakai_juese()
      }
    },
    xuanze_renyuan (date) {
      console.log(this.$refs.all_staff_tree.getCheckedNodes())
      const list = this.$refs.all_staff_tree.getCheckedNodes()

      this.renyuan_list = this.getSimpleCheckedNodes(this.$refs.all_staff_tree.store)
      // this.renyuan_list=list
    },
    getSimpleCheckedNodes (store) {
      const checkedNodes = []
      const traverse = function (node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes
            
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data)
          }
          if (child.indeterminate) {
            traverse(child)
          }
        })
      }
      traverse(store)
      return checkedNodes
    },
    shanchu_ren (i) {
      const list = this.renyuan_list
      const list2 = []
      list.map(item => {
        if (item.id != i.id) {
          list2.push(item)
        }
      })
      console.log(list2)
      console.log(this.gouxuan_key)
      this.$refs.all_staff_tree.setCheckedKeys([])
    },
    dianji_baocun () {
      // set_module_power
      console.log(this.renyuan_list)
      const bumen_ids = []
      const renyuan_ids = []
      const juese_ids = []
      for (let i = 0; i < this.renyuan_list.length; i++) {
        if (this.renyuan_list[i].type == 1) {
          bumen_ids.push(this.renyuan_list[i].id)
        } else if (this.renyuan_list[i].type == 2) {
          renyuan_ids.push(this.renyuan_list[i].id)
        } else if (this.renyuan_list[i].role_group_name == "默认角色组") {
          juese_ids.push(this.renyuan_list[i].role_id)
        }
      }
      if (this.chakan_num == 1) {
        set_all_module_sale({
          data: {
            ent_id: this.$ent_id(),
            module_id: '2',
            type: Number(this.an_shenme) + 1 + '',
            staff_ids: renyuan_ids.length != 0 ? renyuan_ids.join(',') : null,
            dept_ids: bumen_ids.length != 0 ? bumen_ids.join(',') : null,
            role_ids: juese_ids.length != 0 ? juese_ids.join(',') : null,
            read_flag: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            let ids = []
            if (renyuan_ids.length != 0) {
              ids.push(renyuan_ids)
            }
            if (bumen_ids.length != 0) {
              ids.push(bumen_ids)
            }
            if (juese_ids.length != 0) {
              ids.push(juese_ids)
            }
            ids = ids.join(',')
            ids = ids.split(',')
            this.ids = ids
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {}
        })
      } else {
        set_module_power({
          data: {
            ent_id: this.$ent_id(),
            module_id: '2',
            type: Number(this.an_shenme) + 1 + '',
            staff_ids: renyuan_ids.length != 0 ? renyuan_ids.join(',') : null,
            dept_ids: bumen_ids.length != 0 ? bumen_ids.join(',') : null,
            role_ids: juese_ids.length != 0 ? juese_ids.join(',') : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            let ids = []
            if (renyuan_ids.length != 0) {
              ids.push(renyuan_ids)
            }
            if (bumen_ids.length != 0) {
              ids.push(bumen_ids)
            }
            if (juese_ids.length != 0) {
              ids.push(juese_ids)
            }
            ids = ids.join(',')
            ids = ids.split(',')
            this.ids = ids
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {}
        })
      }
    },
    dakai_juese () {
      this.mianbao_list_jue.splice(1, this.mianbao_list_jue.length)
      this.juese_liebiao()
    },
    // 选择角色确定
    // juese_queding(){
    //     console.log(this.qiye_juesery_list_xuan)
    //     // role_name
    //     if(this.qiye_juesery_list_xuan.length!=0){
    //         this.juese_xuanze=this.qiye_juesery_list_xuan.map(item=>item.role_id).join(',')
    //         this.xuanze_juese_name=this.qiye_juesery_list_xuan.map(item=>item.role_name).join(',')
    //     }else{
    //         this.juese_xuanze=null
    //         this.xuanze_juese_name=''
    //     }
    //     this.qiye_juese=false
    // },
    // // 单选人
    dianji_xuanren_jue (i, index) {
      // this.juese_num=index
      // console.log(index)
      // console.log(i)
      // let list=[]
      // list.push(i)
      console.log(i)
      i.zhi = !i.zhi
      if (i.zhi) {
        this.renyuan_list.push(i)
      } else {
        for (let a = 0; a < this.renyuan_list.length; a++) {
          if (this.renyuan_list[a].role_id == i.role_id) {
            this.renyuan_list.splice(a, 1)
          }
        }
      }
      // console.log(e)
      // if(e.path[0].checked==true){
      //     this.qiye_juesery_list_xuan.push(i)
      // }else{
      //     console.log(this.qiye_juesery_list_xuan)
      //     for(let i=0;i<this.qiye_juesery_list_xuan.length;i++){
      //         if(this.qiye_juesery_list[index].role_group_name==this.qiye_juesery_list_xuan[i].role_group_name){
      //             this.qiye_juesery_list_xuan.splice(i,1)
      //         }
      //     }
      // }
    },
    // 点击部门
    dianji_bumen_jue (i) {
      console.log(44444, i)
      this.qiye_juese_list = []
      this.mianbao_list_jue.push({ name: i.role_group_name, dept_id: i.role_group_id })
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_group_id: i.role_group_id
        }
      }).then(ras => {
        if (ras.data.code == 200) {
          const listdata = JSON.parse(ras.data.body.data)
          listdata.map(item => {
            this.$set(item, 'zhi', false)
            this.$set(item, 'label', item.role_name)
            this.$set(item, 'type', '3')
          })
          console.log(99999, listdata)
          this.qiye_juesery_list = listdata
          this.panduan_juese_xuanzhong()
        } else if (ras.data.code == 500) {
        }
      })
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian_jue (i, index) {
      if (this.mianbao_list_jue.length - 1 != index) {
        query_role_group_info_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 200) {
            var date = JSON.parse(res.data.body.data)
            // console.log(date)
            this.qiye_juese_list = date
            this.mianbao_list_jue.splice(index + 1, this.mianbao_list_jue.length)
            query_role_info_list({
              data: {
                ent_id: this.$ent_id(),
                role_group_id: this.mianbao_list_jue[this.mianbao_list_jue.length - 1].dept_id
              }
            }).then(ras => {
              if (ras.data.code == 200) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                listdata.map(item => {
                  this.$set(item, 'zhi', false)
                  this.$set(item, 'label', item.role_name)
                  this.$set(item, 'type', '3')
                })
                this.qiye_juesery_list = listdata
              } else if (ras.data.code == 10150) {
              }
            })
          } else if (res.data.code == 500) {
          }
        })
      }
    },
    keyu_jue () {
      // if (this.juese_sousuo.length == 0) {
      //   this.juese_sousuo = null
      // }
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_name: this.juese_sousuo
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == "200") {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          let op = []
          date.map(a => a.role_name).indexOf(this.juese_sousuo)
          console.log(date)
          this.qiye_juesery_list = date
        } else if (res.data.code == 10028) {
        }
      })
    },
    // 角色列表
    juese_liebiao () {
      query_role_group_info_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_juese_list = date
        } else if (res.data.code == 500) {
        }
      })
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_group_id: this.mianbao_list_jue[this.mianbao_list_jue.length - 1].dept_id
        }
      }).then(ras => {
        if (ras.data.code == 200) {
          const listdata = JSON.parse(ras.data.body.data)
          console.log(99999, listdata)
          listdata.map(item => {
            this.$set(item, 'zhi', false)
            this.$set(item, 'label', item.role_name)
            this.$set(item, 'type', '3')
          })
          this.qiye_juesery_list = listdata
        } else if (ras.data.code == 500) {
        }
      })
    },
    panduan_juese_xuanzhong () {
      for (let i = 0; i < this.renyuan_list.length; i++) {
        for (let a = 0; a < this.qiye_juesery_list.length; a++) {
          if (this.renyuan_list[i].role_id == this.qiye_juesery_list[a].role_id) {
            this.qiye_juesery_list[a].zhi = true
          }
        }
      }
    },
    // 点击全选角色
    dianji_quanxuan_jue () {

    },
    xuanze_zhanshi (index) {
      this.chakan_num = index
      this.chakan()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.entsale {
    height:100%;
    width:100%;
    .con{
        padding: 0.33rem;
        .con_tit{
            font-size: 0.12rem;
            color:#444;
            margin-bottom: 0.1rem;
            display: flex;
            align-items: center;
            li{
                margin-right:0.1rem;
                cursor: pointer;
            }
        }
        .zise_num{
            color:rgb(154, 132, 219);
        }
        .con_tit_xuan{
            display: flex;
            align-items: center;
            li{
                padding: 0 0.12rem;
                border-radius:0.02rem;
                color:#888888;
                border:0.01rem solid #A9A9A9;
                text-align: center;
                line-height: 0.25rem;
                font-size: 0.12rem;
                background: #fff;
                border-radius: 0.03rem;
                cursor:pointer;
                margin-right: 0.1rem;
            }
            .zise{
                background:rgb(154, 132, 219);
                color:#fff;
            }
        }
        .con_box{
            display: flex;
            align-items: center;
            .con_box_left,.con_box_right{
                width:50%;
                border:0.01rem solid #ccc;
                height:430px;
                overflow-y:auto;
                &::-webkit-scrollbar {
                    width: 0.2rem;
                    height: 0.08rem;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .xuanze_sousuo{
                    position: relative;
                    i{
                        position: absolute;
                        top:0.11rem;
                        left: 0.67rem;
                    }
                    input{
                        height:0.3rem;
                        width:2.74rem;
                        border:0.01rem solid #ccc;
                        background:#fff;
                        padding: 0 0.33rem;
                        margin: 0 auto;
                        display: block;
                        margin-top: 0.15rem;
                        border-radius:0.02rem;
                    }
                }
                .mianbaoxie{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    margin-bottom: 0.1rem;
                    margin-left: 0.23rem;
                    margin-top:0.12rem;
                    div{
                        display: flex;
                        align-items: center;
                        cursor:pointer;
                        p{
                            font-size: 0.14rem;
                        }
                        .p2{
                            color:#F96C6C;
                        }
                        .p1{
                            color:#1A2533;
                        }
                    }
                }
                .quanxuan{
                    display: flex;
                    align-items: center;
                    margin-left: 0.24rem;
                    input{
                        -webkit-appearance: none;
                        width: 0.18rem!important;
                        height: 0.17rem!important;
                        display: inline-block;
                        margin: -0.03rem 0.05rem 0 0!important;
                        cursor: pointer;
                        vertical-align: middle;
                        background:url(../../assets/noccc.png) no-repeat;
                        &:checked {
                            background: url(../../assets/okblue.png) no-repeat;
                        }
                        outline-color:none;
                        outline-style :none;
                        outline-width:none;
                    }
                    img{
                        height:0.16rem;
                        width:0.16rem;
                        margin-right: 0.08rem;
                        cursor: pointer;
                    }
                    span{
                        color:#1A2533;
                        font-size: 0.12rem;
                    }
                }
                .nei_box{
                    width:3.45rem;
                    margin: 0 auto;
                    max-height: 2.4rem;
                    overflow:auto;
                    // overflow-y: hidden;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 0.19rem;
                        height: 0;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.12rem;
                        border: 0.06rem solid rgba(0, 0, 0, 0);
                        box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: 0.08rem 0 0 #4A4A4A inset;
                    }
                    .liebiao{
                        width:3.25rem;
                        margin: 0 auto;
                        ul{
                            li{
                                display: flex;
                                height:0.3rem;
                                justify-content: space-between;
                                align-items: center;
                                color:#1A2533;
                                font-size: 0.12rem;
                                padding-left: 0.1rem;
                                cursor:pointer;
                                &:hover{
                                    background: #eee;
                                }
                                .img1{
                                    height:0.11rem;
                                    width:0.2rem;
                                }
                                input{
                                    -webkit-appearance: none;
                                    width: 0.18rem!important;
                                    height: 0.17rem!important;
                                    display: inline-block;
                                    margin: -0.03rem 0.05rem 0 0!important;
                                    cursor: pointer;
                                    vertical-align: middle;
                                    background:url(../../assets/noccc.png) no-repeat;
                                    &:checked {
                                        background: url(../../assets/okblue.png) no-repeat;
                                    }
                                    outline-color:none;
                                    outline-style :none;
                                    outline-width:none;
                                }
                                .img2{
                                    height:0.16rem;
                                    width:0.16rem;
                                    margin-right: 0.08rem;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .con_box_right{
                ul{
                    li{
                        font-size: 0.12rem;
                        padding:0.1rem 0 0.07rem 0.2rem;
                        color:#444;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        margin: 0.06rem 0;
                        position: relative;
                        img{
                            height:0.14rem;
                            width:0.14rem;
                            margin-right: 0.06rem;
                        }
                        .touxiang{
                            height:0.4rem;
                            width:0.4rem;
                            line-height: 0.4rem;
                            background: #1890ff;
                            color:#fff;
                            font-size: 0.2rem;
                            text-align: center;
                            font-family: NSimSun;
                            border-radius: 50%;
                            margin-right: 0.08rem;
                        }
                        div{
                            p{
                                font-size: 0.12rem;
                                &:nth-child(1){
                                    color:#444;
                                }
                                &:nth-child(2){
                                    color:#999;
                                }
                            }
                        }
                        i{
                            position: absolute;
                            right:0.2rem;
                            font-size: 0.2rem;
                            cursor: pointer;
                            top:0.2rem;
                        }
                    }
                    .huise{
                        background: #f6f8f9;
                    }
                }
            }
        }
        .con_foot{
            display: flex;
            justify-content: center;
            margin-top: 0.1rem;
            span{
                padding: 0.08rem 0.13rem;
                border-radius: 0.04rem;
                background: rgb(154, 132, 219);
                font-size: 0.14rem;
                color:#fff;
                display: inline-block;
                cursor: pointer;
            }
        }
    }
}
</style>
